import {
  GET_CAMPAIGNS_FAIL,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGN_DETAIL_FAIL,
  GET_CAMPAIGN_DETAIL_SUCCESS,
  ADD_CAMPAIGN_SUCCESS,
  ADD_CAMPAIGN_FAIL,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAIL,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  campaigns: [],
  campaignDetail: {},
  error: {},
}

const campaigns = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.payload,
      }

    case GET_CAMPAIGNS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: [...state.campaigns, action.payload],
      }

    case ADD_CAMPAIGN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CAMPAIGN_DETAIL_SUCCESS:
      return {
        ...state,
        campaignDetail: action.payload,
      }

    case UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.map(campaign =>
          campaign.id.toString() === action.payload.id.toString()
            ? { campaign, ...action.payload }
            : campaign
        ),
      }

    case UPDATE_CAMPAIGN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.filter(
          campaign => campaign.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_CAMPAIGN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CAMPAIGN_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default campaigns
