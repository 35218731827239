import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { registerUserFailed, registerUserSuccessful } from "./actions"
import { REGISTER_USER } from "./actionTypes"

const fireBaseBackend = getFirebaseBackend()

function* registerUser({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.registerUser,
        user.email,
        user.password
      )
      yield put(registerUserSuccessful(response))
    }
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
