import React from "react"
import { Redirect } from "react-router-dom"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Campaigns from "../pages/Campaigns"
import NewCampaign from "../pages/Campaigns/New"
import UpdateCampaign from "../pages/Campaigns/update"

const userRoutes = [
  { path: "/xuawv2hyin", component: Campaigns },
  { path: "/xuawv2hyin/new", component: NewCampaign },
  { path: "/xuawv2hyin/:campaignId", component: UpdateCampaign },
  { path: "/", exact: true, component: () => <Redirect to="/xuawv2hyin" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
]

export { userRoutes, authRoutes }
