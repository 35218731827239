import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import {
  GET_CAMPAIGNS,
  GET_CAMPAIGN_DETAIL,
  ADD_NEW_CAMPAIGN,
  ADD_BULK_CAMPAIGNS,
  DELETE_CAMPAIGN,
  UPDATE_CAMPAIGN,
} from "./actionTypes"

import {
  addCampaignSuccess,
  addCampaignFail,
  getCampaignsSuccess,
  getCampaignsFail,
  getCampaignDetailSuccess,
  getCampaignDetailFail,
  deleteCampaignSuccess,
  deleteCampaignFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper"

const fireBaseBackend = getFirebaseBackend()

// function* editProfile({ payload: { user } }) {
//   try {
//     const response = yield call(
//       fireBaseBackend.editProfileAPI,
//       user.username,
//       user.idx
//     )
//     yield put(profileSuccess(response))
//   } catch (error) {
//     yield put(profileError(error))
//   }
// }

function* fetchCampaigns() {
  try {
    const response = yield call(fireBaseBackend.getCampaigns)
    yield put(getCampaignsSuccess(response))
  } catch (error) {
    yield put(getCampaignsFail(error))
  }
}

function* fetchCampaignDetail({ payload: campaignId }) {
  try {
    const response = yield call(fireBaseBackend.getCampaignDetail, campaignId)
    yield put(getCampaignDetailSuccess(response))
  } catch (error) {
    yield put(getCampaignDetailFail(error))
  }
}

function* onAddBulkCampaigns() {
  try {
    const response = yield call(fireBaseBackend.addBulkCampaigns)
    yield put(addCampaignSuccess(response))
  } catch (error) {
    yield put(addCampaignFail(error))
  }
}

function* onAddNewCampaign({ payload: campaign }) {
  try {
    const response = yield call(fireBaseBackend.addNewCampaign, campaign)
    yield put(addCampaignSuccess(response))
  } catch (error) {
    yield put(addCampaignFail(error))
  }
}

function* onDeleteCampaign({ payload: campaignId }) {
  try {
    const response = yield call(fireBaseBackend.deleteCampaign, campaignId)
    yield put(deleteCampaignSuccess(response))
  } catch (error) {
    yield put(deleteCampaignFail(error))
  }
}
export function* watchCampaign() {
  yield takeEvery(GET_CAMPAIGNS, fetchCampaigns)
  yield takeEvery(GET_CAMPAIGN_DETAIL, fetchCampaignDetail)
  yield takeEvery(ADD_NEW_CAMPAIGN, onAddNewCampaign)
  // yield takeEvery(ADD_BULK_CAMPAIGNS, onAddBulkCampaigns)
  yield takeEvery(DELETE_CAMPAIGN, onDeleteCampaign)
}

function* CampaignsSaga() {
  yield all([fork(watchCampaign)])
}

export default CampaignsSaga
